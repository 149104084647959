// extracted by mini-css-extract-plugin
export var article = "connected_CG-module--article--Np97E";
export var button = "connected_CG-module--button--zb-J+";
export var buttons = "connected_CG-module--buttons--KKexv";
export var buttonsDiv = "connected_CG-module--buttonsDiv--TG2YA";
export var close = "connected_CG-module--close--BD92H";
export var desc = "connected_CG-module--desc--Ot6jj";
export var eachTitle = "connected_CG-module--eachTitle--Cuo2I";
export var fullScreenButt = "connected_CG-module--fullScreenButt--DhH3v";
export var fullScreenButtDiv = "connected_CG-module--fullScreenButtDiv--iY52P";
export var hide = "connected_CG-module--hide--zN6-L";
export var imgHide = "connected_CG-module--imgHide--GfJUh";
export var isShowCSS = "connected_CG-module--isShowCSS--jNYcv";
export var modalArea = "connected_CG-module--modalArea--Joohr";
export var modalBg = "connected_CG-module--modalBg--qZfNh";
export var modalContent = "connected_CG-module--modalContent--tF2IE";
export var modalContentCSS = "connected_CG-module--modalContentCSS--fRykK";
export var modalContentScale = "connected_CG-module--modalContentScale--lZ7Kk";
export var modalWrapper = "connected_CG-module--modalWrapper--2Uv+k";
export var sumbNail = "connected_CG-module--sumbNail--Zqnud";
export var sumbNailCur = "connected_CG-module--sumbNailCur--yzCzR";
export var sumbNails = "connected_CG-module--sumbNails--A7uTb";
export var sumbNailsGround = "connected_CG-module--sumbNailsGround--rKTxX";
export var titleAndButts = "connected_CG-module--titleAndButts--YG8JA";
export var umeBlack = "connected_CG-module--umeBlack--cPeWa";