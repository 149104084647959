import React, { useState, useEffect, useRef } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Article from "../../components/article"
import LanguageBar from "../../components/languageBar"

import useWindowSize from "../../utilt/useWindowSize"

//CSS
import {article} from "./connected_CG.module.css"
import {eachTitle} from "./connected_CG.module.css"
import {titleAndButts} from "./connected_CG.module.css"
import {desc} from "./connected_CG.module.css"
import {buttonsDiv} from "./connected_CG.module.css"
import {fullScreenButtDiv} from "./connected_CG.module.css"
import {button} from "./connected_CG.module.css"
import {fullScreenButt} from "./connected_CG.module.css"
import {buttons} from "./connected_CG.module.css"
import {sumbNailsGround} from "./connected_CG.module.css"
import {sumbNails} from "./connected_CG.module.css"
import {sumbNail} from "./connected_CG.module.css"
import {sumbNailCur} from "./connected_CG.module.css"
import {umeBlack} from "./connected_CG.module.css"
import {modalArea} from "./connected_CG.module.css"
import {isShowCSS} from "./connected_CG.module.css"
import {modalBg} from "./connected_CG.module.css"
import {modalWrapper} from "./connected_CG.module.css"
import {imgHide} from "./connected_CG.module.css"
import {modalContentCSS} from "./connected_CG.module.css"
import {modalContentScale} from "./connected_CG.module.css"
import {close} from "./connected_CG.module.css"





import getLanguage from "../../utilt/getLangueage"
import calcModalSize from "../../utilt/calcModalSize"
import getIsTouchscreen from "../../utilt/getIsTouchscreen"

//import seoImg from "../../images/seo/seo_connected_CG.jpg"

let isTouch = getIsTouchscreen();


/**
 * ページ
 */
const Connected_CG = (props) => {
  useEffect(() => {
    setTimeout(() => {
      isTouch = getIsTouchscreen();
    }, 0)
  }, [])

  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }


  //画像データ取得
  const data = useStaticQuery(graphql`query QueryConnectedCG {
  allFile(
    filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "connected_CG"}}
  ) {
    edges {
      node {
        base
        childImageSharp {
          gatsbyImageData(
            quality: 50
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
    }
  }
}
`)


  const ImagesLength = 900;
  const rowLength = 60;


  //1 取得した画像データを配列化
  const imagesOld = data.allFile.edges.map(n => n.node);
  const images = imagesOld.slice();

  //2 ファイル名で並べ替え
  function compare(a, b) {
    const baseA = a.base;
    const baseB = b.base;

    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  images.sort(compare);

  //3 配列を二分してサムネイルとモーダル表示用画像を分ける
  const allImages = images.slice(0, ImagesLength);
  const allImagesThumbFlat = images.slice(ImagesLength);

  //4 サムネイルにindexを付ける
  for (let i = 0; i < ImagesLength; i++) {
    allImagesThumbFlat[i].index = i;
  }

  //5 サムネイルを行ごとにさらに配列分け
  const allImagesThumb = Array(15);
  for (let i = 0; i < 15; i++) {
    allImagesThumb[i] = [];
  }
  for (let i = 0, j = 0; i < ImagesLength; i++) {
    if (i !== 0 && i % rowLength === 0) j++
    allImagesThumb[j].push(allImagesThumbFlat[i])
  }




  /**
   * モダール用
   */
  const MaxPicSize = 200;
  const [isShow, setIsShow] = useState(false);
  const [picNum, setPicNum] = useState(30);
  const [picSize, setPicSize] = useState(0.5);
  const [slideLeft, setSlideLeft] = useState(picNum % rowLength * picSize * MaxPicSize + winSize.width / 2);
  const [isFullscreen, setIsFullscreen] = useState(false);
  //setのタイミングがズレるので仮の変数を用意する→lintが文句を言うのでuseRefにした
  let picNumRef = useRef(null);
  picNumRef.current = picNum;

  function handleModal(event, index) {
    event.stopPropagation();
    if (typeof index == "number") {
      setPicNum(index);
      picNumRef.current = index;
    }
    setIsShow(!isShow);
  }

  function handleModalKey(event, index) {
    if (event.keyCode === 13) { //Enter key
      if (typeof index == "number") {
        setPicNum(index);
        picNumRef.current = index;
      }
      setIsShow(true);
    }
  }

  function clickModal(event) {
    event.stopPropagation();
    const modal = document.getElementById("modalContent");
    const clientRect = modal.getBoundingClientRect();

    if (event.clientY < clientRect.top + clientRect.height * 0.23) {
      minusCol();
    } else if (event.clientY > clientRect.top + clientRect.height * 0.77) {
      plusCol();
    } else if (event.clientX > clientRect.left + clientRect.width * 0.5) {
      plusPicNum();
    } else {
      minusPicNum();
    }
  }

  function plusPicNum() {
    let result = (picNum + 1) % ImagesLength
    picNumRef.current = result;
    setPicNum(result);
    result % rowLength === 0 ? fixSlide(0) : fixSlideBySumbnailMoveRight();
  }

  function minusPicNum() {
    let isMinus = (picNum % rowLength) - 1 < 0;
    let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
    setPicNum(result);
    picNumRef.current = result;
    isMinus ? fixSlide(Math.round(MaxPicSize * picSize) * rowLength - winSize.width) : fixSlideBySumbnailMoveLeft();
  }

  function plusCol() {
    let result = picNum + rowLength >= ImagesLength ? picNum + rowLength - ImagesLength : picNum + rowLength
    setPicNum(result);
    picNumRef.current = result;
  }

  function minusCol() {
    let result = picNum - rowLength < 0 ? ImagesLength - rowLength + picNum : picNum - rowLength;
    setPicNum(result);
    picNumRef.current = result;
  }



  //キーボード操作でモダール画像チェンジ
  useEffect(() => {
    //関数コピペ
    function plusPicNum() {
      let result = (picNum + 1) % ImagesLength
      picNumRef.current = result;
      setPicNum(result);
      result % rowLength === 0 ? fixSlide(0) : fixSlideBySumbnailMoveRight();
    }

    function minusPicNum() {
      let isMinus = (picNum % rowLength) - 1 < 0;
      let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
      setPicNum(result);
      picNumRef.current = result;
      isMinus ? fixSlide(Math.round(MaxPicSize * picSize) * rowLength - winSize.width) : fixSlideBySumbnailMoveLeft();
    }

    function plusCol() {
      let result = picNum + rowLength >= ImagesLength ? picNum + rowLength - ImagesLength : picNum + rowLength
      setPicNum(result);
      picNumRef.current = result;
    }

    function minusCol() {
      let result = picNum - rowLength < 0 ? ImagesLength - rowLength + picNum : picNum - rowLength;
      setPicNum(result);
      picNumRef.current = result;
    }

    function zoomIn() {
      let oldSize = picSize;
      let result = picSize * 1.1 > 1 ? 1 : picSize * 1.1;
      setPicSize(result);
      //画面中央でズームさせる
      let oldWindowMidth = slideLeft + winSize.width / 2;
      let newSlideLeft = oldWindowMidth * result / oldSize - winSize.width / 2;
      fixSlide(newSlideLeft)
    }

    function zoomOut() {
      let oldSize = picSize;
      let result = picSize * 0.9 < 0.1 ? 0.1 : picSize * 0.9;
      setPicSize(result);
      //画面中央でズームさせる
      let oldWindowMidth = slideLeft + winSize.width / 2;
      let newSlideLeft = oldWindowMidth * result / oldSize - winSize.width / 2;
      fixSlide(newSlideLeft)
    }

    function toLeft() {
      fixSlide(slideLeft - MaxPicSize * picSize)
    }

    function toRight() {
      fixSlide(slideLeft + MaxPicSize * picSize)
    }

    function fixSlide(slide) {
      let result = slide;
      let curSize = MaxPicSize * picSize;
      if (result > curSize * rowLength - winSize.width) result = curSize * rowLength - winSize.width;
      if (result < 0) result = 0;
      setSlideLeft(Math.round(result / curSize) * curSize);
    }

    function fixSlideBySumbnailMoveLeft() {
      let curSize = MaxPicSize * picSize
      let curSumbX = picNum % rowLength * curSize;
      if (curSumbX - slideLeft < curSize) toLeft();
    }

    function fixSlideBySumbnailMoveRight() {
      let curSize = MaxPicSize * picSize
      let curSumbX = picNum % rowLength * curSize;
      if (curSumbX - slideLeft > winSize.width - curSize * 2) toRight();
    }
    //関数コピペここまで

    function handlePicNum(event) {
      if (event.key === "ArrowRight") {
        plusPicNum();
      }
      else if (event.key === "ArrowLeft") {
        event.preventDefault()
        minusPicNum();
      }
      else if (event.key === "ArrowUp") {
        event.preventDefault()
        minusCol();
      }
      else if (event.key === "ArrowDown") {
        event.preventDefault()
        plusCol();
      }

      //Esc keyでモダール解除
      if (event.keyCode === 27) {
        setIsShow(false);
      }

      //キーボード操作でサムネイル全体画像移動
      if (event.shiftKey && event.key === "ArrowRight") { //16 = shift
        toRight();
      }
      if (event.shiftKey && event.key === "ArrowLeft") {
        toLeft();
      }
      if (event.keyCode === 73) { //73 = i
        zoomIn();
      }
      if (event.keyCode === 79) { //79 = o
        zoomOut();
      }

      /*
      if (event.keyCode === 70) { //70 = f TEST!!
        changeFullScreen();
      }
      */

      //画像選択にfocusをシンクロさせる
      if (isShow) document.getElementById(`pic${String(picNumRef.current)}`).focus();
    }


    //for touchscreen
    let handleTouchStart, handleTouchMove, handleTouchEnd;
    if (isTouch) {
      let startX;
      let moveX;
      let dist = 150;
      let isStart = false;

      handleTouchStart = e => {
        //e.preventDefault();
        if (!isStart) {
          isStart = true;
          startX = e.touches[0].pageX;
        }
      }
      handleTouchMove = e => {
        //e.preventDefault();
        moveX = e.changedTouches[0].pageX;
      }
      handleTouchEnd = e => {
        if (isStart) {
          isStart = false;
          if (startX && moveX) {
            if (startX > moveX && startX > moveX + dist) { // 右から左にスワイプ
              //e.preventDefault()
              toRight();
            } else if (startX < moveX && startX + dist < moveX) { // 左から右にスワイプ
              //e.preventDefault()
              toLeft();
            }
            startX = null;
            moveX = null;
          }
        }

      }
    }

    //window.addEventListener("resize", () => fixSlide(slideLeft));//重すぎるので使わない
    window.addEventListener('keydown', handlePicNum);
    if (isTouch) {
      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      //window.removeEventListener('resize', () => fixSlide(slideLeft));//重すぎるので使わない
      window.removeEventListener('keydown', handlePicNum);
      if (isTouch) {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    }
  }, [picNum, isShow, picSize, slideLeft, winSize])


  /**
   * サムネイルサイズチェンジ
   */
  function zoomIn() {
    let oldSize = picSize;
    let result = picSize * 1.1 > 1 ? 1 : picSize * 1.1;
    setPicSize(result);
    //画面中央でズームさせる
    let oldWindowMidth = slideLeft + winSize.width / 2;
    let newSlideLeft = oldWindowMidth * result / oldSize - winSize.width / 2;
    fixSlide(newSlideLeft)
  }

  function zoomOut() {
    let oldSize = picSize;
    let result = picSize * 0.9 < 0.1 ? 0.1 : picSize * 0.9;
    setPicSize(result);
    //画面中央でズームさせる
    let oldWindowMidth = slideLeft + winSize.width / 2;
    let newSlideLeft = oldWindowMidth * result / oldSize - winSize.width / 2;
    fixSlide(newSlideLeft)
  }

  function toLeft() {
    fixSlide(slideLeft - MaxPicSize * picSize)
  }

  function toRight() {
    fixSlide(slideLeft + MaxPicSize * picSize)
  }

  function fixSlide(slide) {
    let result = slide;
    let curSize = MaxPicSize * picSize;
    if (result > curSize * rowLength - winSize.width) result = curSize * rowLength - winSize.width;
    if (result < 0) result = 0;
    setSlideLeft(Math.round(result / curSize) * curSize);
  }

  function fixSlideBySumbnailMoveLeft() {
    let curSize = MaxPicSize * picSize
    let curSumbX = picNum % rowLength * curSize;
    if (curSumbX - slideLeft < curSize) toLeft();
    //console.log(curSumbX, slideLeft, curSize, winSize.width);
  }

  function fixSlideBySumbnailMoveRight() {
    let curSize = MaxPicSize * picSize
    let curSumbX = picNum % rowLength * curSize;
    if (curSumbX - slideLeft > winSize.width - curSize * 2) toRight();
    // console.log(curSumbX, slideLeft, curSize, winSize.width);
  }

  function changeFullScreen() {
    setIsFullscreen(!isFullscreen);
    if (!isFullscreen) setSlideLeft(0)
    else fixSlide(slideLeft + winSize.width / 2)
  }

  const modaleScale = 2.5;


  return <>
    <Seo
      title={lang === "en" ? "Connected Drawings(CG)" : "繋ぎ絵（ＣＧ）"}
      description={lang === "en" ?
        "An infinite huge picture that grows forever made by connected CG drawn by the technique of Automatism" :
        "オートマティスムの技法で描いたＣＧドローイングを前後左右に描き繋いでいくことで永遠に増殖していく不定形な巨大な絵"
      }
      lang={lang}
      image="https://www.drawinghell.com/ogp_images/seo_connected_CG.jpg"
      pathname="/work/connected_CG"
    />



    {/**
       * ページ＆サムネイル
       */}
    <Layout
      checked="worksParts"
      winSize={winSize}
      footerBaseColor="#000000"
      footerMargintop="0"
    >

      <div
        className={article}
      >
        <div >
          <LanguageBar
            setLang={setLanguage}
            lang={lang}
          />
        </div>
        <div
          className={titleAndButts}
        >

          <h1
            className={eachTitle}
            style={{
              letterSpacing: `${lang === "en" ? "0.05rem" : "0.25rem"}`
            }}
          >
            {lang === "en" ? "Connected Drawings(CG)" : " 繋ぎ絵(CG)"}
          </h1>
          <p className={desc}>
            {lang === "en" ?
              `Digital Drawing, 2480px × 3568px × 900sheets, 2006-2010 ` :
              `デジタルドローイング、2480px × 3568px × 900枚、2006-2010年`}
          </p>

          <div
            className={`${buttonsDiv} ${fullScreenButtDiv}`}
          >
            <button
              type="submit"
              className={`${button} ${fullScreenButt} `}
              onClick={() => changeFullScreen()}
              style={{
                color: `${isFullscreen ? "#66d28c" : "#666666"}`
              }}
            >
              {lang === "en" ? "Full display" : "全表示"}
            </button>
          </div>

          <div
            className={buttonsDiv}
          >
            <span
              className={buttons}
              style={{
                margin: `25px ${5 + 70 * Math.min(winSize.width / 1200, 1) ** 3}px 20px`
              }}
            >
              <button
                type="submit"
                className={button}
                onClick={() => zoomOut()}
                style={{
                  width: `${40 + 20 * Math.min(winSize.width / 1200, 1)}px`,
                  margin: `0px ${2.5 + 10 * Math.min(winSize.width / 1200, 1)}px`,
                }}
                title="zoom out (o)"
              >−</button>
              <button
                type="submit"
                className={button}
                onClick={() => zoomIn()}
                style={{
                  width: `${40 + 20 * Math.min(winSize.width / 1200, 1)}px`,
                  margin: `0px ${2.5 + 10 * Math.min(winSize.width / 1200, 1)}px`,
                }}
                title="zoom in (i)"
              >+</button>

            </span>
            <span
              className={buttons}
              style={{
                margin: `25px ${5 + 70 * Math.min(winSize.width / 1200, 1) ** 3}px 20px`
              }}
            >
              <button
                type="submit"
                className={button}
                onClick={() => toLeft()}
                style={{
                  width: `${40 + 20 * Math.min(winSize.width / 1200, 1)}px`,
                  margin: `0px ${2.5 + 10 * Math.min(winSize.width / 1200, 1)}px`,
                }}
                title="slide to left(Shift + Arrow Left)"
              >←</button>
              <button
                type="submit"
                className={button}
                onClick={() => toRight()}
                style={{
                  width: `${40 + 20 * Math.min(winSize.width / 1200, 1)}px`,
                  margin: `0px ${2.5 + 10 * Math.min(winSize.width / 1200, 1)}px`,
                }}
                title="slide to right(Shift + Arrow Right)"
              >→</button>

            </span>
          </div>

        </div>

        <Article
          isLangBar="false"
          setLang={setLanguage}
          lang={props.lang}
          articleMaxWidth={isFullscreen ? MaxPicSize * picSize * rowLength : winSize.width}
          articleOverflow={isFullscreen ? "auto" : "hidden"}
        //articleBackGroundColor="#000000"
        >


          <div
            className={sumbNailsGround}
          >

            <div
              className={sumbNails}
              style={{
                width: `${MaxPicSize * picSize * rowLength}px`,
                left: `${-1 * slideLeft}px`
              }}
              id="sumbNails"
            >
              {allImagesThumb.map(col => col.map((node) => (
                <div
                  className={isShow && node.index === picNum ? `${sumbNail} ${sumbNailCur}` : sumbNail}
                  onClick={event => handleModal(event, node.index)}
                  onKeyDown={event => handleModalKey(event, node.index)}
                  role="button"
                  tabIndex={0}
                  style={{
                    width: `${MaxPicSize * picSize}px`,
                  }}
                  key={node.base.split(".")[0]}
                  id={"pic" + String(node.index)}
                >
                  <GatsbyImage
                    image={node.childImageSharp.gatsbyImageData}
                    alt={node.base.split(".")[0]} />
                </div>
              )))}
            </div>

          </div>

        </Article>

        <div
          className={umeBlack}
        ></div>
      </div>




    </Layout>



    {/**
       * モーダル
      */}
    <div
      className={
        isShow ? `${modalArea} ${isShowCSS}` : modalArea
      }
    >
      <div
        className={modalBg}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        <div
          className={modalWrapper}
          style={{
            width:
              calcModalSize(winSize,
                905,
                905/1280,
                modaleScale * Math.min(Math.max(winSize.width / 640, 0.6), 1), 0.9) + "px"
          }}
        >

          <div className={imgHide} >
            <GatsbyImage
              image={allImages[picNum + 1 >= ImagesLength ? 0 : picNum + 1].childImageSharp.gatsbyImageData}
              alt={allImages[picNum + 1 >= ImagesLength ? 0 : picNum + 1].base.split(".")[0]} />
          </div>
          <div className={imgHide} >
            <GatsbyImage
              image={allImages[picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1].childImageSharp.gatsbyImageData}
              alt={allImages[picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1].base.split(".")[0]} />
          </div>
          <div className={imgHide} >
            <GatsbyImage
              image={allImages[picNum + rowLength >= ImagesLength ? picNum + rowLength - ImagesLength : picNum + rowLength].childImageSharp.gatsbyImageData}
              alt={allImages[picNum + rowLength >= ImagesLength ? picNum + rowLength - ImagesLength : picNum + rowLength].base.split(".")[0]} />
          </div>
          <div className={imgHide} >
            <GatsbyImage
              image={allImages[picNum - rowLength < 0 ? ImagesLength - rowLength : picNum - rowLength].childImageSharp.gatsbyImageData}
              alt={allImages[picNum - rowLength < 0 ? ImagesLength - rowLength : picNum - rowLength].base.split(".")[0]} />
          </div>

          <div
            className={
              isShow ? `${modalContentCSS} ${modalContentScale}` : modalContentCSS}
            onClick={event => clickModal(event)}
            onKeyDown={() => { }}
            role="button"
            tabIndex={0}
            id="modalContent"
          >
            <GatsbyImage
              image={allImages[picNum].childImageSharp.gatsbyImageData}
              alt={allImages[picNum].base.split(".")[0]} />
          </div>
        </div>
      </div>
      <span
        className={close}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        ×
             </span>
    </div>


  </>;

}

export default Connected_CG



